type Venue = 'konn' | 'sumi' | 'taproom';

interface Config {
  name: Venue;
  pageSize: number;
  menuId: number;
}

const config: { [key: string]: Config } = {
  konn: {
    name: 'konn',
    pageSize: 10,
    menuId: 133351,
  },
  sumi: {
    name: 'sumi',
    pageSize: 12,
    menuId: 232272,
  },
  taproom: {
    name: 'taproom',
    pageSize: 8,
    menuId: 60304,
  },
};

let venue = global.window && window.location.hostname.startsWith('konn') ? 'konn' : 'taproom';

const venueOverride = new URLSearchParams(global.window && window.location.search).get('venue');
if (venueOverride) venue = venueOverride;

export default config[venue];
